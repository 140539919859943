import '../css/styles.css'
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

function footer () {
    return (
<>


<footer id="footer" className="text-lg-start">
    <div className="text-center p-3 text-dark ">
  
      <p className="link-color">Copyright © {new Date().getFullYear()} Dusmekan. All Rights Reserved.</p>
      <a className='link-dark' href="https://www.facebook.com/dusmekan/" target="_blank" rel="noreferrer"><FontAwesomeIcon  className='icon-padding' icon={faFacebookF}/></a>   
      <a className='link-dark' href="https://twitter.com/dusmekan_" target="_blank" rel="noreferrer"><FontAwesomeIcon  className='icon-padding' icon={faTwitter}/></a>   
      <a className='link-dark' href="https://www.instagram.com/dusmekan_/" target="_blank" rel="noreferrer"><FontAwesomeIcon  className='icon-padding' icon={faInstagram}/></a>   
      <a className='link-dark' href="https://www.linkedin.com/company/d%C3%BC%C5%9Fmekan-tasar%C4%B1m/" target="_blank" rel="noreferrer"><FontAwesomeIcon  className='icon-padding' icon={faLinkedin}/></a>   
    </div>
</footer>


</>
);
}


export default footer;
