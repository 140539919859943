import React from 'react';
import ReactDOM from 'react-dom/client';
import Navigation from "./navbar";
import Footer from "./footer";
import Services from "./Services";
import ContactPage from './Contact';
import Gallery from './Gallery';
import { Helmet } from 'react-helmet';

/*
import Single from './SinglePost';
*/
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";



function App() {


    const indexPage = createBrowserRouter([
       <Helmet>
      <title>Düşmekan</title>
      <meta
        name="description"
        content="Elevate your brand with our precision 3D & 2D design and sculpting services. From visual identities to bespoke sculptures, unlock unparalleled creativity with our expert team."
      />
      <meta
        name="keywords"
        content="3D Design, 2D Design, Sculpting for Brands, Brand Aesthetics, Creative Solutions"
      />
    </Helmet>,
        {
          path: "/",
          element: <Services/>
        },
        {
          path: "/contact",
          element: <ContactPage/>
        },
        {
          path: "/work",
          element: <Gallery/>
        },
        /*
        {
          path: "/work/:id",
          element: <Single/>
        }
        */
      ]);
      

      ReactDOM.createRoot(document.getElementById("root")).render(
        <React.StrictMode>
          <Navigation/>
          <RouterProvider router={indexPage} />
          <Footer/>
        </React.StrictMode>
      );


}





/*
root.render(
  <React.StrictMode>

    <Navigation/>
    <Footer/>

  </React.StrictMode>
);*/




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


export default App();
