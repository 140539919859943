import '../css/styles.css'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import pic1 from '../img/1.jpg'
import pic2 from '../img/2.jpg'
import pic3 from '../img/3.jpg'
import pic4 from '../img/4.jpg'
import { Helmet } from 'react-helmet';

function HomePage () {
    return (
<>
<Helmet>
 
 <title>Düşmekan</title>
 <meta
     name="description"
     content="Elevate your brand with our expert 3D and 2D design, sculpting, and branding solutions. Explore the intersection of creativity and precision as we bring brand visions to life through captivating visual identities and bespoke sculptures."
 />
 <meta
     name="keywords"
     content="Brand Design Solutions, 3D and 2D Design Expertise, Sculpting for Brands, Technology and Craftsmanship, Creative Branding"
 />

</Helmet>

<section id="statement">
<Container fluid>
<Row>
<Col lg="6" md="12" sm="12" className='info-spacing'>
<img alt="" 
src= {pic1}
className="shadow-1-strong mb-4 hover-shadow"/>
<h3 className='statement-style'>Design</h3>
<p className='info-text text-dark'>
Unleash the power of imagination with our design services using cutting-edge technology. Our skilled team of 3D and 2D designers transforms concepts into captivating visual experiences. Whether you're envisioning a product prototype, architectural design, or brand identity, our design expertise brings your ideas to life with precision and creativity. Elevate your projects with innovative design solutions that redefine possibilities.
</p>
</Col>
<Col lg="6" md="12" sm="12" className='info-spacing'>
<img alt="" 
src= {pic2}
className="shadow-1-strong mb-4 hover-shadow"/>
<h3 className='statement-style'>Production</h3>
<p className='info-text text-dark'>
Experience seamless and efficient production processes tailored to meet your unique needs. Our production team leverages state-of-the-art technology to bring your desired designs into reality. We always ensure the highest quality in every detail. With a commitment to excellence, we deliver on time and within budget, turning concepts into tangible results that exceed expectations.
</p>
</Col>
</Row>
<Row>
<Col lg="6" md="12" sm="12" className='info-spacing'>
<img alt="" 
src= {pic3}
className="shadow-1-strong mb-4 hover-shadow"/>
<h3 className='statement-style'>Art Work</h3>
<p className='info-text text-dark'>
Immerse yourself in the realm of artistic expression with our bespoke artwork services. Our talented artists blend traditional craftsmanship with digital finesse to create stunning 2D and 3D artworks. Whether you're seeking captivating illustrations, digital paintings, or concept art, our team crafts visually compelling pieces that resonate with your vision. Elevate your projects with unique and eye-catching artwork that tells a story and captivates your audience.
</p>
</Col>
<Col lg="6" md="12" sm="12" className='info-spacing'>
<img alt="" 
src= {pic4}
className="shadow-1-strong mb-4 hover-shadow"/>
<h3 className='statement-style'>3D Product</h3>
<p className='info-text text-dark'>
Discover innovation in every detail with our 3D Product services. From captivating showcases and bespoke store product designs to unforgettable event concepts, our skilled team of 3D and 2D modelers brings your ideas to life. Elevate your brand, captivate your audience, and stand out with visually stunning and immersive experiences.
</p>
</Col>
</Row>
</Container>
</section>


</>
    );
}


export default HomePage;
