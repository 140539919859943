import '../css/styles.css'
import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import useAxios from 'axios-hooks'
import { Helmet } from 'react-helmet';

function FetchPosts() {



    /*const baseURL= "/work/";*/
    const [dataPerPage, setDataPerPage] = useState({})
    const [isLoading, setLoading] = useState(false);
    let [page, setPage] = useState(1)
    let [pageCount, setPageCount] = useState(0);
    const [{ data }] = useAxios({
    url: 'https://api.dusmekan.com/api/works?populate=image&pagination[page]=' + page,
    timeout: 5000
  })
    
    useEffect(() => {
    setDataPerPage(d => ({ ...d, [page]: data }))
    if (data != null) {
      setPageCount(data.meta.pagination.pageCount);
      setLoading(true);
    }
    }, [page, data])

   



   window.addEventListener("scroll",(event) => {
    /*
      let scrollable = document.documentElement.scrollHeight  - window.innerHeight;
      let scrolledHeight = Math.round(window.scrollY);
      const bottomVisible = () =>
      document.documentElement.clientHeight + window.scrollY >=
      (document.documentElement.scrollHeight ||
      document.documentElement.clientHeight);
      const documentElement = document.documentElement;
      const isScrollToBottom = documentElement.clientHeight + documentElement.scrollTop === documentElement.scrollHeight;
      */
      const bottom = Math.ceil(window.innerHeight + window.scrollY) >= (document.documentElement.scrollHeight / 1.25);

     if (bottom && page < pageCount) {
        setPage(page + 1);
     }
})

    
    
   
    let newData = [].concat(...Object.values(dataPerPage).map(p => p?.data));

          
    if (newData != null && isLoading) {

      

      
      const workPosts = newData?.map((element, index) => {

     


        switch (index%11) {
          
           default: {
            return   (
              <>
                
                    <Col sm="12" md="12" lg="4"
                    className="image-box">
                
                    <img alt={element?.attributes.image.data.attributes.name} 
                    src= {"https://api.dusmekan.com" + element?.attributes.image.data.attributes.url}
                    key={index}
                    className="shadow-1-strong mb-4 hover-shadow small-image"/>
                    <div className="content-overlay">
                    <p className="content-title">{element?.attributes.title}</p>
                    <p className="overlay-location">{ element?.attributes.location }</p>
                    </div>
                  
                    </Col>
              </>
            )
           }
           case 3: {
            return   (
              <>
                    <Col sm="12" md="12" lg="6"
                    className="image-box">
                 
                    <img alt={element?.attributes.image.data.attributes.name}  
                    src= {"https://api.dusmekan.com" + element?.attributes.image.data.attributes.url}
                    key={index}
                    className="shadow-1-strong mb-4 hover-shadow medium-image"/>
                    <div className="content-overlay">
                    <p className="content-title">{element?.attributes.title}</p>
                    <p className="overlay-location">{ element?.attributes.location }</p>
                    </div>
                 
                    </Col>
              </>
            )
           }
           case 4: {
            return   (
              <>
                    <Col sm="12" md="12" lg="6"
                    className="image-box">
                
                    <img alt={element?.attributes.image.data.attributes.name}  
                    src= {"https://api.dusmekan.com" + element?.attributes.image.data.attributes.url}
                    key={index}
                    className="shadow-1-strong mb-4 hover-shadow medium-image"/>
                    <div className="content-overlay">
                    <p className="content-title">{element?.attributes.title}</p>
                    <p className="overlay-location">{ element?.attributes.location }</p>
                    </div>
                  
                    </Col>
              </>
            )
           }
           case 8: {
            return   (
              <>
                    <Col sm="12" md="12" lg="6"
                    className="image-box">
               
                    <img alt={element?.attributes.image.data.attributes.name}  
                    src= {"https://api.dusmekan.com" + element?.attributes.image.data.attributes.url}
                    key={index}
                    className="shadow-1-strong mb-4 hover-shadow medium-image"/>
                    <div className="content-overlay">
                    <p className="content-title">{element?.attributes.title}</p>
                    <p className="overlay-location">{ element?.attributes.location }</p>
                    </div>
                  
                    </Col>
              </>
            )
           }
           case 9: {
            return   (
              <>
                    <Col sm="12" md="12" lg="6"
                    className="image-box">
                  
                    <img alt={element?.attributes.image.data.attributes.name}  
                    src= {"https://api.dusmekan.com" + element?.attributes.image.data.attributes.url}
                    key={index}
                    className="shadow-1-strong mb-4 hover-shadow medium-image"/>
                    <div className="content-overlay">
                    <p className="content-title">{element?.attributes.title}</p>
                    <p className="overlay-location">{ element?.attributes.location }</p>
                    </div>
                
                    </Col>
              </>
            )
           }
           case 10: {
            return   (
              <>
                    <Col sm="12" md="12" lg="12"
                    className="image-box">
                       
                    <img alt={element?.attributes.image.data.attributes.name} 
                    src= {"https://api.dusmekan.com" + element?.attributes.image.data.attributes.url}
                    key={index}
                    className="shadow-1-strong mb-4 hover-shadow large-image"/>
                    <div className="content-overlay">
                    <p className="content-title">{element?.attributes.title}</p>
                    <p className="overlay-location">{ element?.attributes.location }</p>
                    </div>
               
                    </Col>
              </>
            )
           }
        }
    
        

         
         
    
    })



if (page === false) {
    window.removeEventListener("scroll",() => {
         console.log("complete");
    });
}
 





        
return (
  <>
 <Helmet>
 
 <title>Work │ Düşmekan</title>
    <meta
      name="description"
      content="Explore our diverse portfolio of 3D and 2D design, sculpting, and brand visuals. Immerse yourself in a journey of creativity, precision, and artistic expression, bringing brand visions to life through captivating identities and sculptures."
    />
    <meta
      name="keywords"
      content="3D Design Showcase, 2D Design Portfolio, Brand Sculpting Examples, Creative Brand Solutions"
    />

</Helmet>
  <section id="works">
  <div className="container-fluid">
  <div className="row content-row" >
  {workPosts}
  </div>
  </div>
  </section>
  </>
  )
  


      
}
  
   




  
}


    


export default FetchPosts;
