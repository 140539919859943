import '../css/styles.css'
import 'bootstrap/dist/css/bootstrap.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './logo.png';


function navbar () {
    return (

<>

          <Navbar expand="md" className='navbar'>
          <Navbar.Brand href="/" className='justify-content-start'>
            <img
              alt=""
              src={logo}
              className="brand-icon"/>  
          </Navbar.Brand>
 
          <Navbar.Toggle className="navbar-toggle-button"  data-bs-target="#navCollapse" aria-controls="navCollapse" aria-expanded="false" aria-label="Toggle navigation" />

          <Navbar.Collapse className='justify-content-end text-center' id='navCollapse'>
          <Nav.Item>
          <Nav.Link href="/" className='navbar-link-style' >Services</Nav.Link>
          </Nav.Item>
          <Nav.Item>
          <Nav.Link href="/work" className='navbar-link-style' >Work</Nav.Link>
          </Nav.Item>
          <Nav.Item>
          <Nav.Link href="/contact" className='navbar-link-style' >Contact</Nav.Link>
          </Nav.Item>
          </Navbar.Collapse>
          </Navbar>
    

  </>
          
    );
}

            


export default navbar;