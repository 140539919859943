import '../css/styles.css'
import 'bootstrap/dist/css/bootstrap.css';
import FetchAPI from "../hooks/fetchData";









function Gallery() {


return (
    <FetchAPI></FetchAPI>
);




}

export default Gallery;