import '../css/styles.css'
import '../css/map.css'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from 'react-helmet';




function ContactPage () {
    return (

<>

<section id="contact">
<Helmet>
 
 <title>Contact | Düşmekan</title>
 <meta
     name="description"
     content="Connect with us to turn your brand's vision into reality. Our expert team is ready to discuss your 3D and 2D design needs, as well as sculpting projects. Let's collaborate and elevate your brand through creative solutions."
 />
 <meta
     name="keywords"
     content="Contact Us, Design Consultation, Sculpting Inquiry, Creative Solutions Contact, Get in Touch"
 />

</Helmet>

<Container fluid>
<Row>
<Col sm="12" md="12" lg="12">
</Col>
</Row>
<h1 className="display-3 contact-text">Contact Us</h1>
</Container>

<Container fluid>
<Row>

<Col lg="4" md="4" sm="12" className="contact-info">
<FontAwesomeIcon icon={faLocationDot}></FontAwesomeIcon>
<h5>Location</h5>
<p>Ortabayir Mh. Dutluk Cd. Yeni Sk. 19-11, 34413 Kagithane/Istanbul</p>


</Col>

<Col lg="4" md="4" sm="12" className="contact-info">
<FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
<h5>Email Adress</h5>
<a class="link-dark" href="mailto:iletisim@dusmekan.com" target="_blank" rel="noreferrer"><p>iletisim@dusmekan.com</p></a>

</Col>

<Col lg="4" md="4" sm="12" className="contact-info">

<FontAwesomeIcon icon={faMobileScreen}></FontAwesomeIcon>
<h5>Phone Number</h5>
<p> +90 506 875 29 39 </p>


</Col>

<Col lg="12" md="12" sm="12" className="contact-info google-maps">

  <iframe
    src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d375.93510475178743!2d29.0021498!3d41.0803396!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDA0JzQ5LjMiTiAyOcKwMDAnMDguNCJF!5e0!3m2!1str!2str!4v1699723649264!5m2!1str!2str"
    width={600}
    height={450}
    style={{ border: 0 }}
    allowFullScreen=""
    loading="lazy"
    title="location"
    referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  

</Col>

</Row>
</Container>
</section>


</>
    ); 
}

export default ContactPage;
